import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import OrderDetail from "../components/Order/Detail";
import {
  CUSTOMERS,
  CUSTOMERS_EMAIL_CAMPAIGNS,
  CUSTOMERS_EMAIL_CAMPAIGNS_NEW,
  CUSTOMERS_LIST,
  CUSTOMER_DETAILS,
  DASHBOARD,
  DETAILS_PAGE,
  FORGET_PASSWORD,
  LOGIN,
  CONVERSATIONS,
  ORDERS,
  ORDERS_DETAILS,
  ORDERS_OVERVIEW,
  POS,
  POS_CREATE_OPTION,
  POS_CREATE_ORDER,
  POS_CREATE_PRODUCT,
  POS_CREATE_SERVICE,
  POS_CREATE_CATEGORY_TYPE,
  POS_EDIT_OPTION,
  POS_EDIT_PRODUCT,
  POS_EDIT_SERVICE,
  POS_SERVICES_AND_PRODUCTS,
  ROOT,
  SCANNER_PAGE,
  SETTINGS,
  // SIGNUP,
  POS_EDIT_CATEGORY_TYPE,
  CUSTOMERS_NEW,
  ONBOARDING,
  NEW_PASSWORD,
  PASSWORD_EMAIL_SENT,
  POS_EDIT_SERVICE_TYPE,
  POS_EDIT_CATEGORY,
  CUSTOMER_ROUTES,
  CONVERSATION_DETAIL,
  CONVERSATION_DETAIL_WITH_ORDER,
  NEW_CONVERSATION,
} from "../constants/FrontendRoutes";
import CreateService from "../pages/CreateService";
import CreateProduct from "../pages/CreateProduct";
import CreateOption from "../pages/CreateOption";
import EditService from "../pages/EditService";
import EditProduct from "../pages/EditProduct";
import EditOption from "../pages/EditOption";
import CustomerDetails from "../pages/CustomerDetails";
import CustomerListPage from "../pages/CustomerList";
import DashboardPage from "../pages/DashBoardPage";
import { EmailCampaignsPage } from "../pages/EmailCampaigns";
import ForgetPasswordPage from "../pages/ForgetPassword";
import LineItemsDetails from "../pages/LineItemsDetails";
import LoginPage from "../pages/Login";
import Messages from "../pages/Messages";
import { NewCampaignPage } from "../pages/NewCampaign";
import NewOrder from "../pages/NewOrder";
import OrderOverview from "../pages/OrderOverview";
import POSPage from "../pages/POS";
import ScannerPage from "../pages/Scanner";
import Setting from "../pages/Setting";
// import SignupPage from "../pages/SignUp";
import ProtectedRoute from "../routes/protectedRoute";
import PublicRoute from "../routes/publicRoute";
import CreateCategoryType from "../pages/CreateCategoryType";
import EditCategoryType from "../pages/EditCategoryType";
import AddNewCustomer from "../pages/AddNewCustomer";
import Onboarding from "../pages/Onboarding";
import NewPasswordPage from "../pages/NewPassword";
import PasswordEmailSent from "../pages/PasswordEmailSent";
import { getRoutes } from "../components/NavBar/config";
import EditServiceType from "../pages/EditServiceType";
import EditCategory from "../pages/EditCategory";
import CustomerLogin from "../pages/CustomerLogin";
import CustomerOrderOverview from "../pages/CustomerOrderOverview";
import CustomerOrderDetails from "../pages/CustomerOrderDetails";
import CustomerLayout from "../components/Layout/CustomerLayout";
import CustomerAccount from "../pages/CustomerAccount";
import CustomerConversations from '../components/CustomerDetails/Conversations';

const App = () => {
  const location = useLocation();
  const routes = getRoutes(location);

  const roles = (route) => {
    return routes.find((r) => r?.path === route)?.roles;
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path={ROOT} element={<Navigate replace to={DASHBOARD} />} />
        <Route
          path={ORDERS}
          element={<Navigate replace to={ORDERS_OVERVIEW} />}
        />
        <Route
          path={CUSTOMERS}
          element={<Navigate replace to={CUSTOMERS_LIST} />}
        />
        <Route
          path={POS}
          element={<Navigate replace to={POS_SERVICES_AND_PRODUCTS} />}
        />
        <Route
          path={DASHBOARD}
          element={
            <ProtectedRoute
              component={DashboardPage}
              roles={roles(DASHBOARD)}
            />
          }
        />
        <Route
          path={ORDERS_OVERVIEW}
          element={
            <ProtectedRoute
              component={OrderOverview}
              roles={roles(ORDERS_OVERVIEW)}
            />
          }
        />

        <Route
          path={ORDERS_DETAILS}
          element={
            <ProtectedRoute
              component={OrderDetail}
              roles={roles(ORDERS_DETAILS)}
            />
          }
        />
        <Route
          path={CUSTOMERS_LIST}
          element={
            <ProtectedRoute
              component={CustomerListPage}
              roles={roles(CUSTOMERS_LIST)}
            />
          }
        />
        <Route
          path={CUSTOMER_DETAILS}
          element={
            <ProtectedRoute
              component={CustomerDetails}
              roles={roles(CUSTOMER_DETAILS)}
            />
          }
        />
        <Route
          path={CUSTOMERS_EMAIL_CAMPAIGNS}
          element={
            <ProtectedRoute
              component={EmailCampaignsPage}
              roles={roles(CUSTOMERS_EMAIL_CAMPAIGNS)}
            />
          }
        />
        <Route
          path={CUSTOMERS_EMAIL_CAMPAIGNS_NEW}
          element={
            <ProtectedRoute
              component={NewCampaignPage}
              roles={roles(CUSTOMERS_EMAIL_CAMPAIGNS_NEW)}
            />
          }
        />
        <Route
          path={CUSTOMERS_NEW}
          element={
            <ProtectedRoute
              component={AddNewCustomer}
              roles={roles(CUSTOMERS_NEW)}
            />
          }
        />
        <Route
          path={POS_SERVICES_AND_PRODUCTS}
          element={
            <ProtectedRoute
              component={POSPage}
              roles={roles(POS_SERVICES_AND_PRODUCTS)}
            />
          }
        />
        <Route
          path={POS_CREATE_ORDER}
          element={
            <ProtectedRoute
              component={NewOrder}
              roles={roles(POS_CREATE_ORDER)}
            />
          }
        />
        <Route
          path={SETTINGS}
          element={
            <ProtectedRoute component={Setting} roles={roles(SETTINGS)} />
          }
        />
        <Route
          path={CONVERSATION_DETAIL}
          element={
            <ProtectedRoute
              component={Messages}
              roles={roles(CONVERSATION_DETAIL)}
            />
          }
        />
        <Route
          path={CONVERSATION_DETAIL_WITH_ORDER}
          element={
            <ProtectedRoute
              component={Messages}
              roles={roles(CONVERSATION_DETAIL)}
            />
          }
        />
        <Route
          path={CONVERSATIONS}
          element={
            <ProtectedRoute
              component={Messages}
              roles={roles(CONVERSATIONS)}
            />
          }
        />
        <Route
          path={NEW_CONVERSATION}
          element={
            <ProtectedRoute
              component={Messages}
              roles={roles(CONVERSATIONS)}
            />
          }
        />
        <Route
          path={SCANNER_PAGE}
          element={
            <ProtectedRoute
              component={ScannerPage}
              roles={roles(SCANNER_PAGE)}
            />
          }
        />
        <Route
          path={POS_CREATE_SERVICE}
          element={
            <ProtectedRoute
              component={CreateService}
              roles={roles(POS_CREATE_SERVICE)}
            />
          }
        />
        <Route
          path={POS_CREATE_PRODUCT}
          element={
            <ProtectedRoute
              component={CreateProduct}
              roles={roles(POS_CREATE_PRODUCT)}
            />
          }
        />
        <Route
          path={POS_CREATE_OPTION}
          element={
            <ProtectedRoute
              component={CreateOption}
              roles={roles(POS_CREATE_OPTION)}
            />
          }
        />
        <Route
          path={POS_CREATE_CATEGORY_TYPE}
          element={
            <ProtectedRoute
              component={CreateCategoryType}
              roles={roles(POS_CREATE_CATEGORY_TYPE)}
            />
          }
        />
        <Route
          path={POS_EDIT_SERVICE}
          element={
            <ProtectedRoute
              component={EditService}
              roles={roles(POS_EDIT_SERVICE)}
            />
          }
        />
        <Route
          path={POS_EDIT_SERVICE_TYPE}
          element={
            <ProtectedRoute
              component={EditServiceType}
              roles={roles(POS_EDIT_SERVICE_TYPE)}
            />
          }
        />
        <Route
          path={POS_EDIT_PRODUCT}
          element={
            <ProtectedRoute
              component={EditProduct}
              roles={roles(POS_EDIT_PRODUCT)}
            />
          }
        />
        <Route
          path={POS_EDIT_OPTION}
          element={
            <ProtectedRoute
              component={EditOption}
              roles={roles(POS_EDIT_OPTION)}
            />
          }
        />
        <Route
          path={POS_EDIT_CATEGORY}
          element={
            <ProtectedRoute
              component={EditCategory}
              roles={roles(POS_EDIT_CATEGORY)}
            />
          }
        />
        <Route
          path={POS_EDIT_CATEGORY_TYPE}
          element={
            <ProtectedRoute
              component={EditCategoryType}
              roles={roles(POS_EDIT_CATEGORY_TYPE)}
            />
          }
        />
      </Route>

      <Route path="/customer" element={<CustomerLayout />}>
        <Route
          path={CUSTOMER_ROUTES.ORDERS_OVERVIEW}
          element={
            <ProtectedRoute
              component={CustomerOrderOverview}
              roles={roles(CUSTOMER_ROUTES.ORDERS_OVERVIEW)}
            />
          }
        />
        <Route
          path={CUSTOMER_ROUTES.MY_ACCOUNT}
          element={
            <ProtectedRoute
              component={CustomerAccount}
              roles={roles(CUSTOMER_ROUTES.MY_ACCOUNT)}
            />
          }
        />
        <Route
          path={CUSTOMER_ROUTES.ORDERS_DETAILS}
          element={
            <ProtectedRoute
              component={CustomerOrderDetails}
              roles={roles(CUSTOMER_ROUTES.ORDERS_DETAILS)}
            />
          }
        />
        <Route
          path={CUSTOMER_ROUTES.MESSAGES}
          element={
            <ProtectedRoute
              component={CustomerConversations}
              roles={roles(CUSTOMER_ROUTES.MESSAGES)}
            />
          }
        />
        <Route
          path={CUSTOMER_ROUTES.MESSAGES_DETAILS}
          element={
            <ProtectedRoute
              component={CustomerConversations}
              roles={roles(CUSTOMER_ROUTES.MESSAGES)}
            />
          }
        />
      </Route>

      <Route path={LOGIN} element={<PublicRoute component={LoginPage} />} />
      {/* <Route path={SIGNUP} element={<PublicRoute component={SignupPage} />} /> */}
      <Route
        path={FORGET_PASSWORD}
        element={<PublicRoute component={ForgetPasswordPage} />}
      />
      <Route
        path={NEW_PASSWORD}
        element={<PublicRoute component={NewPasswordPage} />}
      />
      <Route
        path={PASSWORD_EMAIL_SENT}
        element={<PublicRoute component={PasswordEmailSent} />}
      />
      <Route
        path={DETAILS_PAGE}
        element={<PublicRoute component={LineItemsDetails} redirect={false} />}
      />
      <Route
        path={ONBOARDING}
        element={<ProtectedRoute component={Onboarding} />}
      />
      <Route
        path={CUSTOMER_ROUTES.LOGIN}
        element={<PublicRoute component={CustomerLogin} />}
      />
    </Routes>
  );
};

export default App;
