import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import Lightning from "../../images/lightning.svg";
import SubStatuses from "./SubStatuses";
import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Modal,
  Divider,
  MenuItem,
  Menu,
} from "@mui/material";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
} from "@mui/material";
import { KeyboardArrowDown, CircleSharp } from "@mui/icons-material";
import {
  ORDERS,
  ORDER_LINE_ITEMS,
  RAILS_APP_API_URL,
  STORES,
  STORE_TOKEN,
  UPDATE_IMAGE,
} from "../../constants/BackendRoutes";
import {
  getRequest,
  updateRequest,
  postRequest,
  deleteRequest,
} from "../../api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QRCodeModal from "./QRCodeModal";
import {
  calculateDaysRemaining,
  capitalize,
  convertDateFormat,
  filterLineItems,
  formatTo24Hour,
  generateRandomString,
  groupServicesByType,
} from "../../utils";
import { selectCurrentUser } from "../../redux/authSlice";
import GenerateQRCodeModal from "./GenerateQRCodeModal";
import LabelModal from "./LabelModal";
import EntityPage from "../EntityPage";
import { ORDERS_OVERVIEW } from "../../constants/FrontendRoutes";
import Capsule from "../Capsule";
import {
  CloudUploadIcon,
  GreenTick,
  QRIcon,
  ShipIcon,
} from "../../common/icons";
import OrderDetailsFooter from "./OrderDetailsFooter";
import theme from "../../theme";
import ImageSliderModal from "./ImageSliderModal";
import DropdownButton from "../Form/DropdownButton";
import { addToast } from "../../redux/toastSlice";
import { ORDER_STATUS_ENUM } from "../../constants";
import { fetchUserData } from "../../redux/authActions";
import { resizeFile } from "../../utils/imageResizer";
import { setLoading } from "../../redux/loadingSlice";
import QRScanner from "./QrScanner";
import MoveOrderModal from "../OrderOverview/MoveOrderModal";
import TrackNumberModal from "../OrderOverview/TrackNumberModal";
import CustomDatePicker from "../Form/Field/DatePicker";
import moment from "moment";

const statusEnum = {
  new_order: {
    bg: "#F3F0E8",
    text: "#BF975F",
  },
  shipping: {
    bg: "#E8DAF8",
    text: "#6A549E",
  },
  in_progress: {
    bg: "#D8EDE0",
    text: "#5A8A6E",
  },
  delayed: {
    bg: "#F8E2C9",
    text: "#CA8A68",
  },
  repaired: {
    bg: "#E8E8E8",
    text: "#272523",
  },
  delivered: {
    bg: "#E8E8E8",
    text: "#272523",
  },
};

const SelectButton = styled(Button)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "2px 12px",
  borderRadius: "50px",
  boxShadow: theme.shadows[1],
  textTransform: "none",
}));

const OrderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const inputRef = useRef(null);
  const ref = useRef(null);
  const [order, setOrder] = useState({});
  const [user, setUser] = useState({});
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [openQr, setQrOpen] = useState(false);
  const [trackNumberModal, setTrackNumberModal] = useState(false);

  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [token, setToken] = useState("");
  const [isGenerateQRCode, setIsGenerateQRCode] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [showGreenTick, setShowGreenTick] = useState(false);
  const [isMoveOrderModalOpen, setIsMoveOrderModalOpen] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [destinationName, setDestinationName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    data: null,
    status: "",
    name: "",
    itemId: 0,
  });

  const handleRowClick = (index) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleQrOpen = () => setQrOpen(true);
  const handleQrClose = () => setQrOpen(false);
  const handleImageModal = (
    isOpen,
    data,
    status = "",
    name = "",
    itemId = 0
  ) => {
    setImageModal({
      isOpen,
      data,
      status,
      name,
      itemId,
    });
  };

  const handleDateUpdate = async (e) => {
    const data = {
      order: {
        estimated_completion: e,
      },
    };
    try {
      await updateRequest(
        `${STORES}/${currentUser?.stores[0]?.id}${ORDERS}/${order.id}`,
        data
      );
      dispatch(addToast("Order Est. Date is updated"));
      fetchOrder();
      setEditDate(false);
      document.removeEventListener("mousedown", handleClickOutside);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleLabelModal = async (value) => {
    if (value === "create") {
      setIsLabelModalOpen(true);
    } else if (value === "download") {
      if (!order.shipping_labels || order.shipping_labels.length === 0) {
        dispatch(addToast("No shipping label found!"));
        return;
      }
      for (const label of order.shipping_labels) {
        if (label?.label_pdf?.url) {
          const labelUrl = `${process.env.REACT_APP_BACKEND_URL}${label.label_pdf.url}`;
          window.open(labelUrl, "_blank");
        }
      }
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setEditDate(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const tableHeaderClasses = "font-questrial text-xs text-gray-800";

  const fetchOrder = async () => {
    try {
      const data = await getRequest(
        `${STORES}/${currentUser?.stores?.[0]?.id}${ORDERS}/${id}`,
        {},
        `sub_statuses,user.addresses,order_line_items,shipping_labels&fields=order.*,order_line_item.*,shipping_label.*`
      );
      setOrder(data);
      setSelectedStatus(data?.status);
      setDaysRemaining(calculateDaysRemaining(data?.estimated_completion));
      setUser(data?.user);
      setOrderLineItems(data?.order_line_items);
    } catch (error) {
      dispatch(addToast(error.message || "Something went Wrong!"));
      console.error("Error fetching order:", error);
    }
  };

  useEffect(() => {
    if (token) {
      postToken();
    }
  }, [token]);

  const postToken = async () => {
    const response = await postRequest(`${STORE_TOKEN}`, {
      user_id: currentUser?.id,
      token,
    });

    if (response.data.status === "success") {
      handleOpen();
    }
  };

  const handleScan = async () => {
    setToken(generateRandomString(20));
    handleOpen();
  };

  const handleGenerateQRCode = () => {
    setOpenQrCodeModal(true);
    handleClose();
  };

  const handleScanQRCode = () => {
    handleQrOpen();
  };

  const handleCloseQrCodeModal = () => {
    setOpenQrCodeModal(false);
    setIsGenerateQRCode(false);
  };

  const handleStatusUpdate = async (value) => {
    const data = { order: { status: selectedStatus }, is_send_email: value };
    if (selectedStatus === "shipping") {
      data["order"]["shipping_date"] = new Date();
    } else if (selectedStatus === "in_progress") {
      data["order"]["in_progress_date"] = new Date();
    }
    try {
      await updateRequest(
        `${STORES}/${currentUser?.stores[0]?.id}${ORDERS}/${order.id}`,
        data
      );
      dispatch(addToast("Order status is updated"));
      fetchOrder();
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const calculateProgress = () => {
    if (ORDER_STATUS_ENUM[order.status] === 0) return 0;
    const progressPercentage = parseInt(
      (ORDER_STATUS_ENUM[order.status] / 5) * 100
    );
    if (progressPercentage < 0 || progressPercentage > 100) return 0;
    return progressPercentage;
  };

  function UploadInput({ repairLineItemId, isBefore, isMobile }) {
    const inputId = `contained-button-file-${repairLineItemId}-${
      isBefore ? "before" : "after"
    }`;
    const imageType = isBefore
      ? "order_line_item[before_images]"
      : "order_line_item[after_images]";

    const uploadPicture = async (event) => {
      try {
        dispatch(setLoading(true));
        const files = event.target.files;
        const formData = new FormData();
        for (const file of files) {
          const resizedImage = await resizeFile(file);
          formData.append(`${imageType}[]`, resizedImage);
        }
        await updateRequest(
          `${STORES}/${currentUser?.stores?.[0]?.id}${ORDERS}/${id}/${ORDER_LINE_ITEMS}/${repairLineItemId}${UPDATE_IMAGE}`,
          formData,
          { "Content-Type": "multipart/form-data" }
        );
        await fetchOrder();
        dispatch(addToast("Images Uploaded Successfully!"));
      } catch (error) {
        dispatch(
          addToast(
            error.message || "Something went Wrong! \nImage is not uploaded!"
          )
        );
        console.error("Error uploading images:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <label htmlFor={inputId}>
          <input
            accept="image/*"
            id={inputId}
            multiple
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={uploadPicture}
          />
          <UploadButton
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            sx={{ maxWidth: "140px", maxHeight: isMobile && "40px" }}
          >
            {isMobile ? (
              <div>Upload</div>
            ) : (
              <p className="text-[#4C8C4A] font-normal">
                Browse file
                <span className=" text-gray-500"> or drag & drop</span>
              </p>
            )}
          </UploadButton>
        </label>
      </Box>
    );
  }

  const handleImageDelete = async (itemId, imageType, imageId) => {
    deleteRequest(
      `/stores/${currentUser?.stores?.[0]?.id}/orders/${id}/order_line_items/${itemId}/delete_images`,
      {
        data: { [`${imageType}_image_id`]: imageId },
      }
    );
    fetchOrder();
  };

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusChange = (status) => {
    setIsMoveOrderModalOpen(true);
    setSelectedStatus(status);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function calculateTotalSum(object) {
    if (object.price) return object.price;

    const optionsSum =
      (object.options &&
        object.options.reduce((total, option) => total + option.price, 0)) ||
      0;
    const servicesSum =
      (object.services &&
        object.services.reduce((total, service) => total + service.price, 0)) ||
      0;

    return optionsSum + servicesSum;
  }

  const handleQRCode = (value) => {
    if (value === "scanQR") {
      handleScanQRCode();
    } else if (value === "generateQR") {
      handleGenerateQRCode();
    } else if (value === "scanBarcode") {
      handleScan();
    }
  };

  const formatDate = (dateString) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Get the month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    // Return the formatted date string
    return `${month}/${day}/${year}`;
  };

  const handleTrackingId = () => {
    if (!order.shipping_labels?.length) {
      return "-------";
    }

    const trackingNumbers = order.shipping_labels
      .map((shipment) => ({
        leg: `Leg #${shipment.shipping_leg.split("_")[1]}`,
        number: shipment.tracking_number,
        trackingURL: shipment.tracking_url,
        legNumber: parseInt(shipment.shipping_leg.split("_")[1], 10),
      }))
      .sort((a, b) => a.legNumber - b.legNumber);

    return (
      <>
        <div className="hidden md:block">
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={item.trackingURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
        <div className="md:hidden font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-[min-content,1fr] gap-[12px]">
          <div className="text-[#939291] text-nowrap row-span-2">
            Tracking #
          </div>
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={item.trackingURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
      </>
    );
  };

  const organizeServicesData = (value) => {
    return groupServicesByType(value);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const handleIsMoveOrderModal = () => {
    setDestinationName("");
    setIsMoveOrderModalOpen(false);
    fetchOrder();
  };

  return (
    <EntityPage title="Order Details" breadcrumbs={{ href: ORDERS_OVERVIEW }}>
      <Modal open={trackNumberModal} onClose={() => setTrackNumberModal(false)}>
        <TrackNumberModal
          handleClose={() => setTrackNumberModal(false)}
          openLabelModal={() => setIsLabelModalOpen(true)}
          orderId={order?.id}
          fetchRepairOrders={() => {}}
        />
      </Modal>
      <Modal
        open={isMoveOrderModalOpen}
        onClose={() => handleIsMoveOrderModal()}
      >
        <MoveOrderModal
          handleNotification={(value) => handleStatusUpdate(value)}
          handleClose={() => handleIsMoveOrderModal()}
          openTrackNumberModal={() => setTrackNumberModal(true)}
          handleBack={() => handleIsMoveOrderModal()}
          order={order}
          destinationName={selectedStatus}
        />
      </Modal>
      <Box className="flex flex-col items-center gap-7">
        <Modal open={openQr} onClose={handleQrClose}>
          <QRScanner handleClose={handleQrClose} />
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <QRCodeModal token={token} handleClose={handleClose} />
        </Modal>
        <Modal open={openQrCodeModal} onClose={handleCloseQrCodeModal}>
          <GenerateQRCodeModal
            lineItems={filterLineItems(orderLineItems, "service")}
            dueDate={order.shipping_date}
            handleClose={handleCloseQrCodeModal}
            isGenerateQRCode={isGenerateQRCode}
            user={user}
            order={order}
            orderId={id}
          />
        </Modal>
        <Modal
          open={isLabelModalOpen}
          onClose={() => setIsLabelModalOpen(false)}
        >
          <LabelModal
            isFinalShippingLeg={
              order.shipping_labels?.length > 0 &&
              order.shipping_labels.some(
                (label) => label.shipping_leg == "leg_2"
              )
            }
            handleClose={() => setIsLabelModalOpen(false)}
            customer={user}
            orderId={id}
          />
        </Modal>
        <Modal
          open={imageModal.isOpen}
          onClose={() => handleImageModal(false, null)}
        >
          <ImageSliderModal
            handleClose={() => handleImageModal(false, null)}
            orderId={id}
            modalData={imageModal}
            handleDelete={handleImageDelete}
            currentUser={currentUser}
            handleUpdateImage={fetchOrder}
          />
        </Modal>
        <div className="lg:hidden max-w-[1550px] w-full items-center justify-between">
          <div className="flex-row w-full justify-between items-center">
            <div className="flex flex-col gap-[8px]">
              <div className="flex items-center flex-wrap gap-5 justify-start">
                <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                  Order #{order.id}
                </Typography>
                <div className="flex ms-3 gap-2">
                  {order && order.rush_fee !== 0 && (
                    <>
                      <img
                        alt="LightningLogo"
                        src={Lightning}
                        className="w-5 h-5 mr-2 mt-[2px]"
                      />
                    </>
                  )}
                  <SelectButton
                    onClick={handleStatusClick}
                    endIcon={<KeyboardArrowDown />}
                    sx={{
                      backgroundColor: statusEnum[selectedStatus]?.bg,
                      color: statusEnum[selectedStatus]?.text,
                    }}
                  >
                    <Capsule text={capitalize(selectedStatus)} />
                  </SelectButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "custom-select",
                    }}
                  >
                    {Object.keys(statusEnum).map((statusKey) => (
                      <MenuItem
                        key={statusKey}
                        onClick={() => handleStatusChange(statusKey)}
                        selected={statusKey === selectedStatus}
                        sx={{
                          color: statusEnum[statusKey].text,
                        }}
                      >
                        {capitalize(statusKey)}
                      </MenuItem>
                    ))}
                  </Menu>
                  {order.placement_type === "online" && (
                    <Capsule
                      textColor="text-[#4968A6]"
                      capsuleColor="bg-[#D8E1F8]"
                      text="Online order"
                      LeftIcon={
                        <CircleSharp
                          style={{ fontSize: "0.5rem" }}
                          className="text-[#4968A6]"
                        />
                      }
                    />
                  )}
                  <SubStatuses
                    selectedStatus={selectedStatus}
                    id={id}
                    order={order}
                  />
                </div>
                <div className="w-[1px] mx-3 mt-1 h-10 bg-[#DFDFDF] text-lg"></div>
                <div className="flex flex-col items-start justify-start">
                  <Typography className="text-[#939291]">
                    Est. completion date:
                  </Typography>
                  {order.estimated_completion ? (
                    <div className="flex flex-col">
                      <Typography>
                        <p
                          ref={ref}
                          className={`${
                            daysRemaining < 7 && "text-red-600"
                          } mr-3`}
                          onClick={() => {
                            setEditDate(true);
                          }}
                        >
                          {editDate ? (
                            <CustomDatePicker
                              onOpen={() => {
                                document.removeEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              onClose={() => {
                                document.addEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              value={moment(order.estimated_completion)}
                              onChange={(e) => {
                                handleDateUpdate(e);
                              }}
                            />
                          ) : (
                            <div className="cursor-pointer">
                              {moment(order.estimated_completion).format(
                                "MM/DD/YYYY"
                              )}
                            </div>
                          )}
                        </p>
                      </Typography>
                      {daysRemaining !== null && daysRemaining < 7 && (
                        <Typography className="text-red-600 mx-auto">
                          Due in {daysRemaining} day
                          {daysRemaining !== 1 ? "s" : ""}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <CustomDatePicker
                      onOpen={() => {
                        document.removeEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      onClose={() => {
                        document.addEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      value={moment(order.estimated_completion)}
                      onChange={(e) => {
                        handleDateUpdate(e);
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-row gap-2">
                  <div className="flex gap-5">
                    <DropdownButton
                      disabled={
                        !currentUser?.organization?.shipstation_token?.trim()
                      }
                      button={{
                        text: (
                          <>
                            <ShipIcon />
                            Ship order
                          </>
                        ),
                        returnedValue: "create",
                      }}
                      menuItems={[
                        {
                          text: "Download Shipping label",
                          returnedValue: "download",
                        },
                      ]}
                      onClick={handleLabelModal}
                      variant="outlined"
                      color="secondary"
                    />
                    <DropdownButton
                      button={{
                        text: <QRIcon />,
                        returnedValue: "scanQR",
                      }}
                      menuItems={[
                        {
                          text: "Scan QR code",
                          returnedValue: "scanQR",
                        },
                        {
                          text: "Generate QR code",
                          returnedValue: "generateQR",
                        },
                      ]}
                      onClick={handleQRCode}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-row items-center h-[17px] gap-2">
                <LinearProgress
                  className="flex-grow h-[5px] min-h-[5px] rounded-[100px]"
                  variant="determinate"
                  color="success"
                  value={calculateProgress()}
                />
                <div className="text-[12px] text-[#5D5C5A] font-normal font-['Questrial']">
                  {calculateProgress()}%
                </div>
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <Table aria-labelledby="tableTitle">
                <TableBody>
                  {orderLineItems?.map((row, index) => {
                    const {
                      name,
                      category,
                      categoryType,
                      description,
                      services,
                      options,
                    } = row.details;
                    const isProduct = row.line_item_type === "product";
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row.trackingNumber}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.trackingNumber}
                          style={{
                            border: "none",
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            className="max-w-64"
                          >
                            <Collapse
                              in={openRowIndex === index}
                              timeout={500}
                              unmountOnExit
                            >
                              <div className="flex flex-row w-full">
                                <div className="flex flex-grow flex-col gap-2">
                                  <div
                                    onClick={() => handleRowClick(index)}
                                    className="flex flex-row items-center"
                                  >
                                    <KeyboardArrowUpIcon className="max-h-[16px] text-[#4C8C4A] max-w-[16px] mr-[8px] opacity-75" />
                                    <div>
                                      <p className="font-questrial text-[12px] text-[#4C8C4A]">
                                        {isProduct ? name : `${category}`}
                                      </p>
                                      <p className="font-questrial text-base text-[#4C8C4A]">
                                        {categoryType} -{" "}
                                        {Object.entries(
                                          organizeServicesData(services)
                                        )
                                          .map(([type]) => type)
                                          .join(", ")}
                                      </p>
                                    </div>
                                  </div>
                                  <Divider className="w-full flex-grow mx-10" />
                                  <div className="flex flex-col gap-3">
                                    <div className="flex flex-col gap-3">
                                      {isProduct ? (
                                        <div className="pl-[36px]">
                                          <p className="font-questrial text-xs text-[#939291]">
                                            Product Name
                                          </p>
                                          <p className="font-questrial text-base">
                                            {name}
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="flex flex-col gap-2">
                                            {Object.entries(
                                              organizeServicesData(services)
                                            ).map(([type, names]) => (
                                              <div key={type}>
                                                <p className="pl-[36px] font-questrial font-normal text-[16px] text-[#939291]">
                                                  {type}
                                                </p>
                                                {names.length > 0 ? (
                                                  names.map((name, index) => (
                                                    <p
                                                      key={index}
                                                      className="pl-[36px] font-questrial font-normal text-[16px] text-[#272523]"
                                                    >
                                                      {name}
                                                    </p>
                                                  ))
                                                ) : (
                                                  <p className="pl-[36px] font-questrial text-base">
                                                    ----
                                                  </p>
                                                )}
                                                <Divider className="pt-2" />
                                              </div>
                                            ))}
                                          </div>
                                          <div className="pl-[36px]">
                                            <p className="font-questrial text-[16px] text-[#939291]">
                                              Option(s)
                                            </p>
                                            {options?.length ? (
                                              options.map(({ name }, index) => (
                                                <p
                                                  key={index}
                                                  className="font-questrial text-base"
                                                >
                                                  {name}
                                                </p>
                                              ))
                                            ) : (
                                              <p className="font-questrial text-base">
                                                ---
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <Divider />
                                    <div className="pl-[36px] flex flex-col gap-1">
                                      <p className="font-questrial text-[16px] text-[#939291]">
                                        Description
                                      </p>
                                      <p className="font-questrial text-base">
                                        {description === ""
                                          ? "No description provided"
                                          : description}
                                      </p>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                                      <div className="text-[#939291]">
                                        Order created date
                                      </div>
                                      <div className="text-[#272523]">
                                        {convertDateFormat(order.created_at)}
                                      </div>
                                      {order?.shipping_labels?.length > 0 && (
                                        <div className="text-[#939291]">
                                          Shipping date
                                        </div>
                                      )}
                                      {order?.shipping_labels?.length > 0 && (
                                        <div className="text-[#272523]">
                                          {convertDateFormat(
                                            order.shipping_date
                                          )}
                                        </div>
                                      )}
                                      <div className="text-[#939291]">
                                        In progress date
                                      </div>
                                      <div className="text-[#272523]">
                                        {convertDateFormat(
                                          order.in_progress_date
                                        )}
                                      </div>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] text-[#939291] font-normal flex flex-row pl-[36px] justify-between mr-10">
                                      <div className="flex flex-col gap-2">
                                        <div>Before image(s)</div>
                                        {row?.before_images_data?.length >
                                          0 && (
                                          <div
                                            className="relative w-[112px] h-[59px] cursor-pointer"
                                            onClick={() =>
                                              handleImageModal(
                                                true,
                                                row.before_images_data,
                                                "before",
                                                isProduct ? name : categoryType,
                                                row.id
                                              )
                                            }
                                          >
                                            <img
                                              className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                              src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                              alt="Before"
                                            />
                                            <div
                                              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                                row?.before_images_data
                                                  ?.length > 0 &&
                                                "bg-black bg-opacity-50 rounded-md"
                                              }`}
                                            >
                                              {row?.before_images_data?.length -
                                                1 !==
                                                0 && (
                                                <div className="flex text-[12px] text-center flex-col">
                                                  <div className="font-['Montserrat'] font-[700]">
                                                    +{" "}
                                                    {row?.before_images_data
                                                      ?.length - 1}{" "}
                                                    more
                                                  </div>
                                                  <div className="font-['Questrial'] font-normal">
                                                    See all
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        <div>
                                          <UploadInput
                                            isBefore={true}
                                            repairLineItemId={row.id}
                                            isMobile={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="flex flex-col gap-2">
                                        <div>After image(s)</div>
                                        {row?.after_images_data?.length > 0 && (
                                          <div
                                            className="relative w-[112px] h-[59px] cursor-pointer"
                                            onClick={() =>
                                              handleImageModal(
                                                true,
                                                row.after_images_data,
                                                "after",
                                                isProduct ? name : categoryType,
                                                row.id
                                              )
                                            }
                                          >
                                            <img
                                              className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                              src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                              alt="Before"
                                            />
                                            <div
                                              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                                row?.after_images_data?.length >
                                                  1 &&
                                                "bg-black bg-opacity-50 rounded-md"
                                              }`}
                                            >
                                              <div className="flex flex-col">
                                                {row?.after_images_data
                                                  ?.length -
                                                  1 !==
                                                  0 && (
                                                  <div className="flex text-[12px] text-center flex-col">
                                                    <div className="font-['Montserrat'] font-[700]">
                                                      +{" "}
                                                      {row?.after_images_data
                                                        ?.length - 1}{" "}
                                                      more
                                                    </div>
                                                    <div className="font-['Questrial'] font-normal">
                                                      See all
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div>
                                          <UploadInput
                                            isBefore={false}
                                            repairLineItemId={row.id}
                                            isMobile={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <Divider />
                                    <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                                      <div className="text-[#939291]">
                                        Price
                                      </div>
                                      <div className="font-['Montserrat'] text-[18px] font-[600] text-[#272523]">
                                        ${calculateTotalSum(row.details)}
                                      </div>
                                    </div>
                                    {order?.shipping_labels?.length > 0 && (
                                      <Divider />
                                    )}
                                    {order?.shipping_labels?.length > 0 &&
                                      handleTrackingId()}
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                            <Collapse
                              in={openRowIndex !== index}
                              timeout={500}
                              unmountOnExit
                            >
                              <div
                                onClick={() => handleRowClick(index)}
                                className="flex flex-row items-center"
                              >
                                <KeyboardArrowDownIcon className="max-h-[16px] max-w-[16px] mr-[8px] opacity-75" />
                                <div className="flex flex-col gap-2">
                                  <p className="font-questrial text-[12px] text-[#5D5C5A]">
                                    {isProduct ? name : `${category}`}
                                  </p>
                                  <p>
                                    {categoryType} -{" "}
                                    {Object.entries(
                                      organizeServicesData(services)
                                    )
                                      .map(([type]) => type)
                                      .join(", ")}
                                  </p>
                                </div>
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </div>
        <Paper className="lg:block hidden max-w-[1550px] w-full p-5 items-center justify-between">
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                {order && order.rush_fee !== 0 && (
                  <>
                    <img
                      alt="LightningLogo"
                      src={Lightning}
                      className="w-5 h-5 mr-2 mt-[2px]"
                    />
                  </>
                )}
                <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                  Order #{order.id}
                </Typography>
                <SelectButton
                  onClick={handleStatusClick}
                  endIcon={<KeyboardArrowDown />}
                  sx={{
                    backgroundColor: statusEnum[selectedStatus]?.bg,
                    color: statusEnum[selectedStatus]?.text,
                  }}
                >
                  <Capsule text={capitalize(selectedStatus)} />
                </SelectButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "custom-select",
                  }}
                >
                  {Object.keys(statusEnum).map((statusKey) => (
                    <MenuItem
                      key={statusKey}
                      onClick={() => handleStatusChange(statusKey)}
                      selected={statusKey === selectedStatus}
                      sx={{
                        color: statusEnum[statusKey].text,
                      }}
                    >
                      {capitalize(statusKey)}
                    </MenuItem>
                  ))}
                </Menu>
                {order.placement_type === "online" && (
                  <Capsule
                    textColor="text-[#4968A6]"
                    capsuleColor="bg-[#D8E1F8]"
                    text="Online order"
                    LeftIcon={
                      <CircleSharp
                        style={{ fontSize: "0.5rem" }}
                        className="text-[#4968A6]"
                      />
                    }
                  />
                )}
                <SubStatuses
                  selectedStatus={selectedStatus}
                  id={id}
                  order={order}
                />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col">
                  <Typography>Progress of Order</Typography>
                  <div className="flex items-center lg:w-[300px]">
                    <div className="w-full mr-1">
                      <LinearProgress
                        className="rounded-[20%]"
                        variant="determinate"
                        color="success"
                        value={calculateProgress()}
                      />
                    </div>
                    <Typography>{calculateProgress()}%</Typography>
                  </div>
                </div>
                <div className="w-[1px] mx-3 mt-1 h-10 bg-[#DFDFDF] text-lg"></div>
                <div className="flex flex-col items-start justify-start w-[40%]">
                  <Typography className="text-[#939291]">
                    Est. completion date:
                  </Typography>
                  {order.estimated_completion ? (
                    <div className="flex flex-col">
                      <Typography>
                        <p
                          ref={ref}
                          className={`${
                            daysRemaining < 7 && "text-red-600"
                          } mr-3`}
                          onClick={() => {
                            setEditDate(true);
                          }}
                        >
                          {editDate ? (
                            <CustomDatePicker
                              onOpen={() => {
                                document.removeEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              onClose={() => {
                                document.addEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              value={moment(order.estimated_completion)}
                              onChange={(e) => {
                                handleDateUpdate(e);
                              }}
                            />
                          ) : (
                            <div className="cursor-pointer">
                              {moment(order.estimated_completion).format(
                                "MM/DD/YYYY"
                              )}
                            </div>
                          )}
                        </p>
                      </Typography>
                      {daysRemaining !== null && daysRemaining < 7 && (
                        <Typography className="text-red-600 mx-auto">
                          Due in {daysRemaining} day
                          {daysRemaining !== 1 ? "s" : ""}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <CustomDatePicker
                      onOpen={() => {
                        document.removeEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      onClose={() => {
                        document.addEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      value={moment(order.estimated_completion)}
                      onChange={(e) => {
                        handleDateUpdate(e);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-row gap-2">
              <div className="flex justify-between gap-2">
                <div className="flex items-center gap-2">
                  <div
                    className={`transition-opacity duration-1000 ${
                      showGreenTick ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    <GreenTick />
                  </div>
                  <Typography color="textSecondary">
                    <span className="text-nowrap">Last saved on</span>{" "}
                    <span className="text-nowrap">
                      {formatTo24Hour(order.updated_at)}
                    </span>
                  </Typography>
                </div>
              </div>
              <div className="flex gap-5">
                <DropdownButton
                  disabled={
                    !currentUser?.organization?.shipstation_token?.trim()
                  }
                  button={{
                    text: (
                      <>
                        <ShipIcon />
                        Ship order
                      </>
                    ),
                    returnedValue: "create",
                  }}
                  menuItems={[
                    {
                      text: "Download Shipping label",
                      returnedValue: "download",
                    },
                  ]}
                  onClick={handleLabelModal}
                  variant="outlined"
                  color="secondary"
                />
                <DropdownButton
                  button={{
                    text: "Scan and track",
                    returnedValue: "scanQR",
                  }}
                  menuItems={[
                    {
                      text: "Scan and track",
                      returnedValue: "scanQR",
                    },
                    {
                      text: "Generate QR code",
                      returnedValue: "generateQR",
                    },
                  ]}
                  onClick={handleQRCode}
                />
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <TableContainer>
                <Table className="min-w-[750px]" aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell className={tableHeaderClasses}>
                        Order Items
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Order Created
                      </TableCell>
                      {order?.shipping_labels?.length > 0 && (
                        <TableCell className={tableHeaderClasses}>
                          Shipping Date
                        </TableCell>
                      )}
                      <TableCell className={tableHeaderClasses}>
                        In progress date
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Before image(s)
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        After image(s)
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Price
                      </TableCell>
                      {order?.shipping_labels?.length > 0 && (
                        <TableCell className={tableHeaderClasses}>
                          Tracking #
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderLineItems?.map((row, index) => {
                      const {
                        name,
                        category,
                        categoryType,
                        description,
                        services,
                        options,
                      } = row.details;
                      const isProduct = row.line_item_type === "product";
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <React.Fragment key={row.trackingNumber}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.trackingNumber}
                            style={{
                              border: "none",
                            }}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              className="max-w-64"
                              onClick={() => handleRowClick(index)}
                            >
                              <Collapse
                                in={openRowIndex === index}
                                timeout={500}
                                unmountOnExit
                              >
                                <div className="flex flex-row items-center">
                                  <KeyboardArrowUpIcon />
                                  <div className="flex flex-col gap-2">
                                    <p className="font-questrial text-xs text-[#5D5C5A]">
                                      Item {index + 1}
                                    </p>
                                    <p className="font-questrial text-base text-[#4C8C4A]">
                                      {isProduct
                                        ? name
                                        : `${category} -
                                      ${categoryType}`}
                                    </p>
                                    <Divider className="mx-10" />
                                    <div className="flex flex-col gap-2 pl-2">
                                      <div className="flex flex-col gap-1">
                                        {isProduct ? (
                                          <>
                                            <p className="font-questrial text-xs text-[#939291]">
                                              Product Name
                                            </p>
                                            <p className="font-questrial text-base">
                                              {name}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <div>
                                              {Object.entries(
                                                organizeServicesData(services)
                                              ).map(([type, names]) => (
                                                <div key={type}>
                                                  <p className="font-questrial text-xs text-[#939291]">
                                                    {type}
                                                  </p>
                                                  {names.length > 0 ? (
                                                    names.map((name, index) => (
                                                      <p
                                                        key={index}
                                                        className="font-questrial text-base"
                                                      >
                                                        {name}
                                                      </p>
                                                    ))
                                                  ) : (
                                                    <p className="font-questrial text-base">
                                                      ----
                                                    </p>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                            <Divider />
                                            <div>
                                              <p className="font-questrial text-xs text-[#939291]">
                                                Option(s)
                                              </p>
                                              {options?.length ? (
                                                options.map(
                                                  ({ name }, index) => (
                                                    <p
                                                      key={index}
                                                      className="font-questrial text-base"
                                                    >
                                                      {name}
                                                    </p>
                                                  )
                                                )
                                              ) : (
                                                <p className="font-questrial text-base">
                                                  ---
                                                </p>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <Divider />
                                      <div className="flex flex-col gap-1">
                                        <p className="font-questrial text-xs text-[#939291]">
                                          Description
                                        </p>
                                        <p className="font-questrial text-base">
                                          {description === ""
                                            ? "No description provided"
                                            : description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                              <Collapse
                                in={openRowIndex !== index}
                                timeout={500}
                                unmountOnExit
                              >
                                <div className="flex flex-row items-center">
                                  <KeyboardArrowDownIcon />
                                  <div className="flex flex-col gap-2">
                                    <p className="font-questrial text-xs text-gray-700">
                                      Item {index + 1}
                                    </p>
                                    <p className="font-questrial text-base text-gray-900">
                                      {isProduct
                                        ? name
                                        : `${category} -
                                      ${categoryType}`}
                                    </p>
                                  </div>
                                </div>
                              </Collapse>
                            </TableCell>
                            <TableCell>
                              {convertDateFormat(order.created_at)}
                            </TableCell>
                            {order?.shipping_labels?.length > 0 && (
                              <TableCell>
                                {convertDateFormat(order.shipping_date)}
                              </TableCell>
                            )}
                            <TableCell>
                              {convertDateFormat(order.in_progress_date)}
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                {row?.before_images_data?.length > 0 && (
                                  <div
                                    className="relative w-[131px] h-[68px] cursor-pointer"
                                    onClick={() =>
                                      handleImageModal(
                                        true,
                                        row.before_images_data,
                                        "before",
                                        isProduct ? name : categoryType,
                                        row.id
                                      )
                                    }
                                  >
                                    <img
                                      className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                      src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                      alt="Before"
                                    />
                                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                      {row?.before_images_data?.length - 1 !==
                                        0 && (
                                        <div className="flex flex-col">
                                          <Typography
                                            style={theme.typography.body}
                                          >
                                            +{" "}
                                            {row?.before_images_data?.length -
                                              1}{" "}
                                            more
                                          </Typography>
                                          <Typography className="font-montserrat text-xs text-center">
                                            See all
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <UploadInput
                                  isBefore={true}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                <Box className="flex flex-col">
                                  {row?.after_images_data?.length > 0 && (
                                    <div
                                      className="relative w-[131px] h-[68px] cursor-pointer"
                                      onClick={() =>
                                        handleImageModal(
                                          true,
                                          row.after_images_data,
                                          "after",
                                          isProduct ? name : categoryType,
                                          row.id
                                        )
                                      }
                                    >
                                      <img
                                        className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                        src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                        alt="Before"
                                      />
                                      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                        <div className="flex flex-col">
                                          {row?.after_images_data?.length -
                                            1 !==
                                            0 && (
                                            <div className="flex flex-col">
                                              <Typography
                                                style={theme.typography.body}
                                              >
                                                +{" "}
                                                {row?.after_images_data
                                                  ?.length - 1}
                                                more
                                              </Typography>
                                              <Typography className="font-montserrat text-xs text-center">
                                                See all
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Box>
                                <UploadInput
                                  isBefore={false}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              $ {calculateTotalSum(row.details)}
                            </TableCell>
                            {order?.shipping_labels?.length > 0 && (
                              <TableCell>{handleTrackingId()}</TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Paper>
        {(!isEmpty(user) || !isEmpty(order)) && (
          <OrderDetailsFooter
            user={user}
            order={order}
            setShowGreenTick={setShowGreenTick}
            fetchOrder={fetchOrder}
          />
        )}
      </Box>
    </EntityPage>
  );
};

export default OrderDetailsPage;

const UploadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.background.paper),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  boxShadow: "none",
  textTransform: "none",
  fontSize: 12,
  padding: "10px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
}));
