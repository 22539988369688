import React, { useEffect, useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import Overview from "../components/CustomerDetails/Overview";
import OrderHistory from "../components/CustomerDetails/OrderHistory";
import MessageHistory from "../components/CustomerDetails/MessageHistory";
import ContactHistory from "../components/CustomerDetails/ContactHistory";
import { STORES, USERS } from "../constants/BackendRoutes";
import { getRequest } from "../api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";

const CustomerDetails = () => {
  const [activeLink, setActiveLink] = useState("overview");
  const [customerData, setCustomerData] = useState();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { id } = useParams();

  const overviewRef = useRef(null);
  const orderHistoryRef = useRef(null);
  const messageHistoryRef = useRef(null);
  const contactHistoryRef = useRef(null);

  const linksConfig = [
    {
      id: "overview",
      label: "Overview",
      component: Overview,
      ref: overviewRef,
    },
    {
      id: "order_history",
      label: "Order history",
      component: OrderHistory,
      ref: orderHistoryRef,
    },
    {
      id: "message_history",
      label: "Message history",
      component: MessageHistory,
      ref: messageHistoryRef,
    },
    {
      id: "contact_history",
      label: "Contact history",
      component: ContactHistory,
      ref: contactHistoryRef,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const data = await getRequest(
          `${STORES}/${currentUser?.stores[0].id}${USERS}/${id}`,
          {},
          "addresses,orders.order_line_items,orders.last_message.user&fields=order.*,order_line_item.*,addresses.human_readable"
        );
        setCustomerData(data);
        console.log(data);
      } catch (error) {
        dispatch(addToast(error));
      } finally {
        dispatch(setLoading(false))
      }
    };

    fetchData();
  }, [currentUser, id]);


  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EntityPage
      title="Customer details"
      breadcrumbs
      className="bg-[#F8F8F8]"
    >
      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden lg:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              <button
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 text-left ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-82px-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref} id={link.id}>
                <Component userData={customerData} />
              </div>
            );
          })}
        </div>
      </div>
    </EntityPage>
  );
};

export default CustomerDetails;
